import { Options } from "vue-class-component";

import * as echarts from "echarts/core";
import { TitleComponent, TitleComponentOption, TooltipComponent, TooltipComponentOption, LegendComponent, LegendComponentOption } from "echarts/components";
import { PieChart, PieSeriesOption } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import Mixins from "../mixins/mixins";

echarts.use([TitleComponent, TooltipComponent, LegendComponent, PieChart, CanvasRenderer, LabelLayout]);

type EChartsOption = echarts.ComposeOption<TitleComponentOption | TooltipComponentOption | LegendComponentOption | PieSeriesOption>;

@Options({
    name: "app-monitor-dashboard-running-state-pie",
})
export default class Pie extends Mixins {
    public mounted() {
        this.option = this.pipOption;
        this.init(echarts, this.$refs.chartDom as HTMLElement, "svg").then();
    }

    public async init(echarts: any, dom: HTMLElement, renderer = "svg"): Promise<void> {
        this.myEcharts = echarts;
        this.chartDom = dom;
        await this.$nextTick(() => {
            this.myEcharts = echarts;
            this.initErd(echarts, dom, renderer);
            this.initChart(echarts, dom, renderer);
            Promise.resolve();
        }).catch((error) => {
            Promise.reject(error);
        });
    }

    public refreshData() {
        const nowOption = {
            title: {
                subtext: Object.keys(this.eChartData.runPieData).length + "环",
            },
            series: [
                {
                    data: this.pieData,
                },
            ],
        };
        this.option.title.subtext = Object.keys(this.eChartData.runPieData).length + "环";
        this.option.series[0].data = this.pieData;
        this.chart.setOption(nowOption);
    }

    get pieData(): any {
        const objectArray: any = {};
        for (const key in this.eChartData.runPieData) {
            if (this.eChartData.runPieData.hasOwnProperty(key)) {
                const statusName = this.eChartData.runPieData[key] === 1 ? "运行" : "待机";
                if (!objectArray[statusName]) objectArray[statusName] = { name: statusName, value: 0 };
                objectArray[statusName]["value"]++;
            }
        }
        return Object.values(objectArray);
    }

    get pipOption(): EChartsOption {
        return {
            title: {
                text: "上线环数",
                subtext: Object.keys(this.eChartData.runPieData).length + "环",
                left: "center",
                textStyle: {
                    color: "#999",
                    fontWeight: "normal",
                    fontSize: 14,
                },
            },
            tooltip: {
                trigger: "item",
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                },
            },
            series: [
                {
                    name: "运行情况",
                    type: "pie",
                    radius: "50%",
                    data: this.pieData,
                    label: {
                        formatter: "{name|{b}}\n{pre|{d}%}",
                        minMargin: 5,
                        edgeDistance: 10,
                        lineHeight: 18,
                        rich: {
                            name: {
                                fontSize: 14,
                                color: "#fff",
                                align: "center",
                            },
                            pre: {
                                fontSize: 12,
                                color: "#999",
                                align: "center",
                            },
                        },
                    },
                    labelLine: {
                        lineStyle: {
                            color: "rgba(255, 255, 255, 0.3)",
                        },
                        smooth: 0.2,
                        length: 10,
                        length2: 20,
                    },
                },
            ],
        };
    }
}
