import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-722b0e7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "chartDom",
  style: {"position":"absolute","top":"0","left":"0","right":"0","bottom":"0"}
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
}